.card {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 400px;
    max-width: 700px;
    gap: 4px;
    padding: 1rem;
    border-radius: var(--border-radius);
    border: 0.063rem solid var(--color-border);
}