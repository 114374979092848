* {
    font-family: "Caixa-Regular", sans-serif;
    font-size: 1rem;
    line-height: 120%;
    color: var(--color-text);
    list-style: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}