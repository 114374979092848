input,
.input {
  border: none;
  outline: none;
  height: 2.75rem;
  width: 100%;
  border-radius: var(--border-radius);
  border: 0.063rem solid var(--color-border);
  background: #fff;
  padding: 0px 0.5rem;
}

input[type="text"] {
  margin-top: 4px;
}