.radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.3s ease;

    &-active {
        background-color: var(--color-secondary);
    }
}

.custom-radio {
    margin-right: 15px;
}

.box-radio {
    display: flex;
    margin-bottom: 15px;
}
.phone-validation {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
  
    h2 {
      margin-bottom: 10px;
    }
  
    p {
      margin: 5px 0;
    }
  
    .code-inputs {
      display: flex;
      gap: 10px;
  
      input {
        width: 60px;  // Aumentando o tamanho dos campos de entrada
        height: 60px; // Aumentando o tamanho dos campos de entrada
        text-align: center;
        font-size: 18px;
        border: 1px solid #ccc;
        border-radius: 5px;
  
        &:focus {
          border-color: #007bff;
          outline: none;
        }
      }
    }
  
    .resend-message {
      margin-top: 10px;
      color: #888;
    }
  }

#timer {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    padding: 5px 10px;
    display: inline-block;

  
}

.red-code {
  
    color: #f82323;
    font-size: 16px;
}