.form {
  width: 100%;
  background-color: white;
  padding: 2rem 1.5rem;

  &__error {
    width: 100%;
    background-color: var(--color-error);
    padding: 2px 8px;
    border-radius: 0 0 var(--border-radius) var(--border-radius);

    span {
      color: white;
      font-size: 0.75rem;
    }
  }

  &--error {
    border-color: var(--color-error);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &--disable {
    background-color: var(--color-disable);
  }
}

@media (min-width: 768px) {
  .form {
    width: 442px;
  }
}