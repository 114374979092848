.b {
  &-card {
    border: 0.063rem solid var(--color-border);
  }

  &-radius {
    border-radius: var(--border-radius);
  }

  &-top {
    border-top: 0.063rem solid var(--color-border);
  }

  &-right {
    border-right: 0.063rem solid var(--color-border);
  }

  &-bottom {
    border-bottom: 0.063rem solid var(--color-border);
  }

  &-left {
    border-left: 0.063rem solid var(--color-border);
  }

  &-titulo {
    border-bottom: 0.125rem solid var(--color-primary);
    line-height: 3.5rem;
  }
}

.bd {
  &-top {
    border-top: 0.125rem solid var(--color-bd-primary);
  }

  &-whatsapp {
    border: 0.125rem solid var(--color-whatsapp);
  }
}