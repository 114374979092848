.content {
  width: 100%;
  margin: auto 1rem;

  &-step {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    row-gap: 8px;
    justify-content: center;
    width: 100%;
    height: 20.313rem;
    border: 0.063rem dashed var(--color-border-step);
    padding: 1.5rem 1.25rem;

    p {
      color: var(--color-grafite);
      font-size: 0.75rem;
    }
  }
}

@media (min-width: 64rem) {
  .content {
    width: 69.5rem;
  }
}
