.bg {
  &-secondary {
    background-color: var(--color-bg-secondary);
  }

  &-primary {
    background-color: var(--color-primary);
  }

  &-resume {
    background-color: var(--color-resume);
  }

  &-white {
    background-color: white;
  }
}