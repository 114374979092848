.pill {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    border: 0.063rem solid var(--color-inativo);
    border-radius: 45px;
    color: var(--color-grafite);
    font-size: 14px;
    cursor: pointer;

    &--active,
    &:active {
        background-color: var(--color-third);
        border-color: var(--color-third);
        color: white;
    }
}