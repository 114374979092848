.copy {
    cursor: pointer;

    &:hover {
        color: var(--primary-color);
    }

    &:active {
        color: red;
    }
}