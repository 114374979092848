:root {
  --color-primary: #005ca9;
  --color-secondary: #f39200;
  --color-third: #4FC8B1;

  --color-analyze: #058ce1;
  --color-ativo: #dcf1ff;
  --color-attention: #ff005c;
  --color-border: #CBCBCB;
  --color-border-scroll-button: #015086;
  --color-border-step: #a5aab5;
  --color-bg-hover: #f6f9f9;
  --color-disable: #eceeef;
  --color-grafite: #3a4859;
  --color-listras-: #F8FAFD;
  --color-resume: #f0f0f0;
  --color-title: #2e484d;
  --color-text: #1F2A47;
  --color-whatsapp: #5BD066;

  --color-error: #e82b11;
  --color-info-primary: #f9fbfb;
  --color-success: #005ca9;
  --color-warning: #f39200;

  --color-bg-secondary: #EBEBEF;

  --color-bd-primary: #E2E2E9;

  --color-inativo: #4C556C;
}