button {
  height: 2.75rem;
  padding: 0 1.5rem;
  border-radius: var(--border-radius);
  background-color: var(--color-secondary);
  border: 0.063rem solid var(--color-secondary);
  color: white;
  font-weight: 600;

  &:active {
    color: var(--color-secondary);
    background-color: white;
  }

  &.btn__expansivel {
    width: auto;
  }

  &.btn__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.875rem;
    height: 2.875rem;
    background-color: var(--color-primary);
    border: 0.188rem solid var(--color-border-scroll-button);
    border-radius: 50%;

    &:active {
      background-color: var(--color-border-scroll-button);
    }
  }

  &.btn__whatsapp {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 8px;
    margin-bottom: 20px;
    background-color: var(--color-whatsapp);
    border: 0.063rem solid var(--color-whatsapp);

    img {
      width: 20px;
    }

    &:active {
      background-color: white;
      color: var(--color-whatsapp);
    }
  }
}