.arrow-rotate {
    transform: rotate(180deg);
}

.progresso-rotate {
    animation: girar 1s linear infinite;
}

@keyframes girar {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}