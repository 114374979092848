.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  &-content {
    max-height: 90%;
    max-width: calc(100% - 2rem);
    border-radius: 1rem;
    overflow-y: auto;
    background-color: white;
  }
}
