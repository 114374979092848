@tailwind base;
@tailwind components;
@tailwind utilities;

@import "reset";

@import "animation";
@import "background";
@import "border";
@import "button";
@import "card";
@import "color";
@import "content";
@import "copy";
@import "font";
@import "form";
@import "icon";
@import "info";
@import "input";
@import "label";
@import "modal";
@import "pill";
@import "select";
@import "shadow";
@import "size";
@import "radio";
@import "text";