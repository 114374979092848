.price {
  font-size: 1.2rem;
}

.mensalidade {
  font-size: 1.4rem;
}
.txt {
  &-bold {
    font-family: "Futura-Bold", sans-serif !important;
  }

  &-light {
    font-family: "Futura-Light", sans-serif !important;
  }

  &-attention {
    color: var(--color-attention);
    font-size: 600;
  }

  &-grafite {
    color: var(--color-grafite);
  }

  &-feedback-title {
    font-size: 2.938rem;
    font-weight: 700;
  }

  &-feedback-text {
    font-size: 1.375rem;
    font-weight: 600;
    color: var(--color-grafite);
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-inativo {
    color: var(--color-inativo);
  }

  &-info {
    color: var(--color-secondary);
    font-size: 0.75rem;
    text-decoration-line: underline;
  }

  &-link {
    color: var(--color-primary);
    font-size: 0.75rem;
    text-decoration-line: underline;
  }

  &-primary {
    color: var(--color-primary);
  }

  &-success {
    color: var(--color-success);
  }

  &-title {
    font-weight: bold;
    color: var(--color-title);
  }

  &-text {
    color: var(--color-text);
  }

  &-warning {
    color: var(--color-warning);
  }

  &-white {
    color: white;
  }

  &-whatsapp {
    color: var(--color-whatsapp);
  }
}